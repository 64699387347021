<template>
    <div>
        <v-btn
		color="primary"
		class="px-15 mr-5"
		width="250"
		@click="startProgrammingCard()">
			GET ID FROM CARD READER
		</v-btn>

        <v-dialog 
        v-model="dialog1"
		width="300"
        persistent>
            <v-card>
                <v-card-title>Select card reader</v-card-title>	
                <v-card-text class="black--text py-2 messageWrapper">
                    <v-select
                    v-model="selectedCardReader"
                    :items="dropdownCardReaders"
                    item-value="value"
                    item-text="name"
                    label="Card reader">
                    </v-select>

                    <p class="error--text">{{errorMessage}}</p>
                    
                    <div class="actionButtonsWrapper">
                        <v-btn
                        color="grey"
                        class="mr-0 mt-0"
                        text
                        @click="dialog1=false, errorMessage='', selectedCardReader=null, logId=null"
                        >
                        CANCEL
                        </v-btn>
                        <v-btn
                        color="primary"
                        class="mr-0 mt-0"
                        text
						width="150"
                        @click="programCard()"
                        >
                        PROGRAM CARD
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog 
        v-model="dialog2"
		width="400"
        persistent>
            <v-card>
                <v-card-title>{{selectedCardReaderName}}</v-card-title>	
                <v-card-text class="black--text py-2 messageWrapper">
                    <p>Wait for it...</p>

                    <v-progress-linear
					v-model="value"
					color="green"
					height="25"
					></v-progress-linear>

					<p class="mt-5">Remaining time: {{remainingTime}} seconds</p>
                    
                    <div class="actionButtonsWrapper">
                        <v-btn
                        color="grey"
                        class="mr-0 mt-0"
                        text
                        @click="result=resultDialogItems['cancel'], breakProgramCard()"
                        >
                        CANCEL
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

		<v-dialog 
        v-model="resultDialog" 
		width="270"
        persistent>
            <v-card style="display:flex; flex-direction: column; align-items: center;">
                <v-card-text class="black--text pt-7 pb-5 messageWrapper" style="text-align: center;">
					<p class="font-weight-medium" style="font-size: 20px; line-height: 1.4;" v-bind:style="{'color':result.textColor}">{{result.title}}</p>
					<p v-if="result.message">{{result.message}}</p>
					<v-icon large class="mb-5 mt-3" v-bind:style="{'color': result.iconColor}">{{result.icon}}</v-icon>
                    
                    <div class="actionButtonsWrapper">
                        <v-btn
                        color="grey"
                        class="mr-0 mt-0"
                        text
                        @click="resultDialog=false"
                        >
                        CLOSE
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'ProgramCardDialog',

	data: function(){
		return {
			dialog1: false,
			dialog2: false,
			resultDialog: false,
			resultDialogItems: {
				"success": {
					icon: "mdi-check-bold",
					iconColor: "green",
					title: "Card added corectly for ",
					message: null,
					textColor: "black",
				},
				"cancel": {
					icon: "mdi-close-circle",
					iconColor: "red",
					title: "Operation canceled",
					message: null,
					textColor: "red",
				},
				"timedOut": {
					icon: "mdi-alert-circle",
					iconColor: "red",
					title: "Timed out",
					message: "Try again",
					textColor: "black",
				},
				"cardAssigned": {
					icon: "mdi-alert-circle",
					iconColor: "red",
					title: "Card already assigned to ",
					message: null,
					textColor: "black",
				},
				"workerNotExist": {
					icon: "mdi-alert-circle",
					iconColor: "red",
					title: "Worker does not exists.",
					message: null,
					textColor: "black",
				},

			},
			result: {
				icon: "mdi-alert-circle",
				iconColor: "red",
				title: "Timed out",
				message: "Try again",
				textColor: "black",
			},
			value: 0,
			interval: 0,
			remainingTime: 60,
			selectedCardReader: null,
			errorMessage: "",
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
		}
	},
    computed: {
        dropdownCardReaders(){
            let ddCardReaders=[]
            if(this.cardReaders){
                for(let item of this.cardReaders){
                    ddCardReaders.push({
                        value: item.id,
                        name: item.name
                    })
                }
            }
            return ddCardReaders
        },
        selectedCardReaderName(){
            if(this.dropdownCardReaders && this.selectedCardReader){
                return this.dropdownCardReaders.find(item => item.value==this.selectedCardReader).name
            }
            return ""
        }
    },
    props: {
        cardReaders: [],
		workerId: Number,
		workerFullName: String,
    },
	watch:{
		value (val) {
        if (val < 100) return
		clearInterval(this.interval)
		this.remainingTime=60
		this.dialog2=false
		this.result="timedOut"
		this.resultDialog=true
      },
	},
	mounted(){
	},
    methods: {
		startProgrammingCard(){
			if(this.cardReaders &&this.cardReaders.length==1){
				this.selectedCardReader=this.cardReaders[0].id
				this.dialog2=true
				this.sendRequestToCardReader()
			}
			else{
				this.dialog1=true
			}
		},
		startBuffer (logId) {
			clearInterval(this.interval)
			this.remainingTime=60

			this.interval = setInterval(() => {
				this.value += 100/60
				this.remainingTime--
				this.checkIfCardRegistered(logId)
			}, 1000)
		},
        programCard(){
            if(!this.selectedCardReader){
                this.errorMessage="Select card reader"
                return
            }
			this.sendRequestToCardReader()
        },
		sendRequestToCardReader(){
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/program-card/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data:{
							"card_reader_id": this.selectedCardReader,
							"worker_id": this.workerId
					}
				}).then((response) => {
					this.logId=response.data.id
					this.errorMessage=""
					this.dialog1=false
					this.dialog2=true
					this.startBuffer(response.data.id)
				})
				.catch((error) => {
					// this.closeDialog()
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						// this.$router.push("/");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						//To poniżej do zmiany
						if(error.response.data.card_reader_id && error.response.data.card_reader_id[0].code=="CARD_READER_NOT_ONLINE"){
							this.dialog2=false
							this.dialog1=true
							this.errorMessage="Selected device is not online"
							return
						}
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		checkIfCardRegistered(logId){
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/program-card/check/"+logId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				}).then((response) => {
					if(response.data.message[0].code=="SUCCESS"){
						Object.assign(this.result,this.resultDialogItems["success"]) // clone object
						this.result.title += this.workerFullName
						this.closeDialog()
						this.emitCardId()
					}
					else if(response.data.message[0].code=="CARD_ASSIGNED"){
						Object.assign(this.result, this.resultDialogItems["cardAssigned"])
						this.result.title += response.data.card_info[0].full_name
						this.closeDialog()
					}
					else if(response.data.message[0].code=="CANCEL"){
						this.result=this.resultDialogItems["cancel"]
						this.closeDialog()
					}
					else if(response.data.message[0].code=="WORKER_DOES_NOT_EXIST"){
						this.result=this.resultDialogItems["workerNotExist"]
						this.closeDialog()
					}
					else if(response.data.message[0].code=="NO_ACTION"){
						this.closeDialog()
					}
				})
				.catch((error) => {
					if (typeof(error.response) === 'undefined'){
						this.errorDialog.errorTitle = "Network error";
						this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
						this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						// this.$router.push("/");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						console.log(error.response.data)
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		breakProgramCard(){
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/cards/program-card/check/"+this.logId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				}).then(() => {
					this.closeDialog()
				})
				.catch((error) => {
					if (typeof(error.response) === 'undefined'){
						this.errorDialog.errorTitle = "Network error";
						this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
						this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						// Redirect to login page
						// this.$router.push("/");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						console.log(error.response.data)
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		emitCardId(cardId) {
			this.$emit('getCardId', cardId);
		},
		closeDialog(){
			this.logId=null
			this.dialog2=false
			this.selectedCardReader=null
			this.value=0
			this.remainingTime=60
			clearInterval(this.interval)
			this.resultDialog=true
		}
    }
};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.messageWrapper{
    font-size: 1em;
}


</style>
